<template>
    <div class="container">
        <div class="header">
            <h3>Welcome to the...</h3>
            <h1>Charlie Zone</h1>
            <p>6-a-side Football Team Picker</p>
        </div>
        <div class="body">
            <div class="player-section">
                <div class="player-entry-container">
                    <div class="player-entry-header">
                        <h2>Player Entry</h2>
                        <span class="player-count">Players: {{ players.length }}</span>
                    </div>
                    <br/>
                    <div class="player-entry">
                        <label for="player-name">Player:</label>
                        <input 
                            id="player-name"
                            v-model="newPlayer.name"
                            type="text" 
                            placeholder="Enter Player Name" 
                        />
                        <br/>
                        <label for="attack">Attack:</label>
                        <input 
                            id="attack"
                            v-model.number="newPlayer.attack"
                            type="number" 
                            placeholder="1-10"
                            min="1"
                            max="10"
                        />
                        <br/>
                        <label for="defence">Defence:</label>
                        <input 
                            id="defence"
                            v-model.number="newPlayer.defence"
                            type="number" 
                            placeholder="1-10"
                            min="1"
                            max="10"
                        />
                        <br/>
                        <button @click="addPlayer">Add Player</button>
                        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
                    </div>
                </div>
                <div v-if="players.length" class="player-list-container">
                    <h2>Player List</h2>
                    <ul class="player-list">
                        <li v-for="(player, index) in players" :key="player.name">
                            <div class="player-info">
                                <img v-if="nameToImage[player.name]" :src="nameToImage[player.name]" alt="Player Icon" class="player-image" />
                                {{ player.name }} - OVR: {{ player.attack + player.defence }}
                            </div>
                            <button @click="removePlayer(index)">X</button>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="team-selector-container">
                <h2>Team Selector</h2>
                <div class="team-selector">
                    <button @click="generateTeams">Generate Teams</button>
                    <br/>
                    <button @click="randomTeams">Randomize Teams</button>
                    <br/>
                    <div v-if="teams.Colours.length || teams.Whites.length" class="teams-container">
                        <div class="team-container">
                            <div class="team-header">
                                <div class="team-name">
                                    <h3>Colours</h3>
                                    <img src="../assets/img/colour-team-icon.png" alt="Colours Icon" class="team-icon" />
                                </div>
                                <span class="team-rating">Total Rating: {{ totalRatingColours }}</span>
                            </div>
                            <ul>
                                <li v-for="player in teams.Colours" :key="player.name">
                                    {{ player.name }}
                                    <img v-if="nameToImage[player.name]" :src="nameToImage[player.name]" alt="Player Icon" class="player-image" />
                                </li>
                            </ul>
                        </div>
                        <div class="team-container">
                            <div class="team-header">
                                <div class="team-name">
                                    <h3>Whites</h3>
                                    <img src="../assets/img/white-team-icon.png" alt="White Icon" class="team-icon" />
                                </div>
                                <span class="team-rating">Total Rating: {{ totalRatingWhites }}</span>
                            </div>
                            <ul>
                                <li v-for="player in teams.Whites" :key="player.name">
                                    {{ player.name }}
                                    <img v-if="nameToImage[player.name]" :src="nameToImage[player.name]" alt="Player Icon" class="player-image" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomePage',
    data() {
        return {
            newPlayer: {
                name: '',
                attack: '',
                defence: ''
            },
            players: [],
            teams: {
                Colours: [],
                Whites: []
            },
            totalRatingColours: 0,
            totalRatingWhites: 0,
            errorMessage: '',
            nameToImage: {
                'Ben' : require('../assets/img/player-icons/cz-ben.png'),
                'ben': require('../assets/img/player-icons/cz-ben.png'),
                'Max': require('../assets/img/player-icons/cz-max.png'),
                'max': require('../assets/img/player-icons/cz-max.png'),
                'Callum': require('../assets/img/player-icons/cz-callum.png'),
                'callum': require('../assets/img/player-icons/cz-callum.png')
            }
        };
    },
    methods: {
        addPlayer() {
            if (this.newPlayer.name && this.isValidRating(this.newPlayer.attack) && this.isValidRating(this.newPlayer.defence)) {
                this.players.push({ ...this.newPlayer });
                this.newPlayer.name = '';
                this.newPlayer.attack = 0;
                this.newPlayer.defence = 0;
                this.errorMessage = '';
            } else {
                this.errorMessage = 'Please enter valid ratings between 1 and 10 for attack and defence.';
            }
        },
        removePlayer(index) {
            this.players.splice(index, 1);
        },
        generateTeams() {
            // Reset teams and total ratings
            this.teams.Colours = [];
            this.teams.Whites = [];
            this.totalRatingColours = 0;
            this.totalRatingWhites = 0;

            // Sort players by their total rating (attack + defence) in descending order
            const sortedPlayers = [...this.players].sort((a, b) => (b.attack + b.defence) - (a.attack + a.defence));

            // Allocate players to teams
            sortedPlayers.forEach(player => {
                const playerRating = player.attack + player.defence;
                if (this.totalRatingColours <= this.totalRatingWhites) {
                    this.teams.Colours.push(player);
                    this.totalRatingColours += playerRating;
                } else {
                    this.teams.Whites.push(player);
                    this.totalRatingWhites += playerRating;
                }
            });
        },
        randomTeams() {
            this.teams.Colours = [];
            this.teams.Whites = [];
            this.totalRatingColours = 0;
            this.totalRatingWhites = 0;

            const shuffledPlayers = [...this.players].sort(() => Math.random() - 0.5);

            shuffledPlayers.forEach((player, index) => {
                const playerRating = player.attack + player.defence;
                if (index % 2 === 0) {
                    this.teams.Colours.push(player);
                    this.totalRatingColours += playerRating;
                } else {
                    this.teams.Whites.push(player);
                    this.totalRatingWhites += playerRating;
                }
            });
        },
        isValidRating(rating) {
            return rating >= 1 && rating <= 10;
        }
    }
}
</script>

<style scoped>
@import "../assets/css/homePage.css";
</style>